<template>
  <b-overlay :show="loadingOverlay" rounded="sm">
    <div v-if="Coupons !== null">
      <b-modal
          id="modal-delete"
          cancelTitle="خیر"
          centered
          ok-title="بله"
          @ok="DeleteCoupon"
      >
        <span>آیا از حذف کردن این کوپن اطمینان دارید ؟ </span>
      </b-modal>
      <b-modal
          id="modal-edit"
          centered
          ok-only
          ok-title="بروزرسانی کوپن"
          @ok.preventDefault()="UpdateCoupon"
      >
        <b-row v-if="SelectedCoupon">
          <b-col cols="12">
            <b-form-group>
              <label for="email">کد فعالسازی کوپن</label>
              <b-form-input
                  v-model="SelectedCoupon.code"
                  placeholder="ex: FURFIT2022"
                  type="text"
              />
            </b-form-group>
            <b-form-group>
              <label for="email">توضیحات کوپن</label>
              <b-form-input
                  v-model="SelectedCoupon.condition"
                  placeholder="ex: FURFIT2022"
                  type="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label>مقدار تخفیف</label>
            <input
                v-model="SelectedCoupon.value"
                :disabled="SelectedCoupon.type === 3"
                class="form-control"
                type="number"
            />
          </b-col>
          <b-col md="6">
            <label>نوع تخفیف</label>
            <select v-model="SelectedCoupon.type" class="form-control">
              <option :value="1">درصدی</option>
              <option :value="2">عددی</option>
              <option :value="3">ارسال رایگان</option>
            </select>
          </b-col>
          <b-col md="12">
            <label>تعداد</label>
            <input
                v-model="SelectedCoupon.count"
                class="form-control"
                type="number"
            />
          </b-col>
          <b-col md="6">
            <label>تاریخ شروع</label>
            <date-picker
                type="date"
                displayFormat="jYYYY/jMM/jDD"
                v-model="SelectedCoupon.startDate"
            ></date-picker>
          </b-col>
          <b-col md="6">
            <label>تاریخ انقضا</label>
            <date-picker
                type="date"
                displayFormat="jYYYY/jMM/jDD"
                v-model="SelectedCoupon.expireDate"
            ></date-picker>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
          id="modal-Add"
          centered
          ok-only
          ok-title="افزودن کوپن"
          @ok.preventDefault()="CreateCoupon"
      >
        <b-row>
          <b-col cols="12">
            <label for="email">کد فعالسازی کوپن</label>
            <textarea
                class="form-control"
                v-model="coupon.code"
                style="max-height: 200px; resize: none"
                placeholder="ex: FURFIT2022"
                rows="10"
            ></textarea>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label for="email">توضیحات کوپن</label>
              <b-form-input
                  v-model="coupon.condition"
                  placeholder=""
                  type="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label>مقدار تخفیف</label>
            <input
                v-model="coupon.value"
                :disabled="coupon.type === 3"
                class="form-control"
                type="number"
            />
          </b-col>
          <b-col md="6">
            <label>نوع تخفیف</label>
            <select v-model="coupon.type" class="form-control">
              <option :value="1">درصدی</option>
              <option :value="2">عددی</option>
              <option :value="3">ارسال رایگان</option>
            </select>
          </b-col>
          <b-col md="3">
            <label>تعداد</label>
            <input v-model="coupon.count" class="form-control" type="number"/>
          </b-col>
          <b-col md="12"></b-col>
          <b-col md="6">
            <label>تاریخ شروع</label>
            <date-picker
                displayFormat="jYYYY/jMM/jDD"
                type="date"
                v-model="coupon.startDate"
            ></date-picker>
          </b-col>
          <b-col md="6">
            <label>تاریخ انقضا</label>
            <date-picker
                displayFormat="jYYYY/jMM/jDD"
                type="date"
                v-model="coupon.expireDate"
            ></date-picker>
          </b-col>
        </b-row>
      </b-modal>
      <!-- Table Container Card -->
      <b-card class="mb-0" no-body>
        <b-col class="my-2" lg="12" md="12">
          <b-row>
            <b-col class="my-2" lg="2" md="12">
              <b-button v-b-modal.modal-Add variant="success">
                افزودن کوپن جدید
              </b-button>
            </b-col>
            <b-col class="my-2 ml-auto" lg="2" md="12">
              <clear-sorts-btn @clear="sortBy = ''"></clear-sorts-btn>
            </b-col>
          </b-row>
        </b-col>

        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="Coupons"
            bordered
            class="position-relative"
            :sort-by.sync="sortBy"
            empty-text="موردی موجود نیست!"
            primary-key="id"
            responsive
            show-empty
            striped
        >
          <!-- Column: delete -->
          <template #cell(value)="data">
            <div class="d-flex flex-column justify-content-center">
              <small v-if="data.item.value !== 0">
                {{ Intl.NumberFormat("fa-IR").format(data.item.value) }}
              </small>
              <small v-else>ارسال رایگان</small>

              <div>
                <b-badge
                    v-if="data.item.type === 1"
                    class="mr-75"
                    pill
                    variant="primary"
                >
                  درصدی
                </b-badge>
                <b-badge
                    v-if="data.item.type === 2"
                    class="mr-75"
                    pill
                    variant="primary"
                >
                  عددی
                </b-badge>
              </div>
            </div>
          </template>
          <template #cell(startDate)="data">
            <small>{{
                new Date(data.item.startDate).toLocaleDateString("fa-IR")
              }}</small>
          </template>
          <template #cell(expireDate)="data">
            <small>{{
                new Date(data.item.expireDate).toLocaleDateString("fa-IR")
              }}</small>
          </template>
          <template #cell(operation)="data">
            <div class="d-flex align-items-center justify-content-between">
              <div
                  v-b-modal.modal-delete
                  class="cursor-pointer d-flex flex-row"
                  @click="SetSelectedCoupon(data.item)"
              >
                <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
              </div>
              <div
                  v-b-modal.modal-edit
                  class="cursor-pointer d-flex flex-row"
                  @click="SetSelectedCoupon(data.item)"
              >
                <feather-icon class="text-primary" icon="EditIcon" size="20"/>
              </div>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-end"
                cols="12"
                sm="12"
            >
              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                      style="transform: rotate(180deg)"
                  />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BFormFile,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
  BModal,
  BFormGroup,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  CreateCoupon,
  DeleteCoupon,
  GetCouponByCode,
  GetAllCoupon,
  UpdateCoupon,
} from "@/libs/Api/Coupons";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import helper from "@/libs/Helper";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";

export default {
  title: "لیست کوپن ها  - پنل ادمین انزا مد",
  name: "Coupons",
  data() {
    return {
      Coupons: null,
      totalCount: null,
      sortBy: '',
      loadingOverlay: false,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: "couponId",
          label: "شناسه",
          sortable: true
        },
        {
          key: "code",
          label: "کد",
          sortable: true
        },
        {
          key: "value",
          label: "میزان و نوع تخفیف",
          sortable: true
        },
        {
          key: "count",
          label: "تعداد کل",
          sortable: true
        },
        {
          key: "usedCount",
          label: "تعداد استفاده شده",
          sortable: true
        },
        {
          key: "startDate",
          label: "تاریخ شروع",
          sortable: true
        },
        {
          key: "expireDate",
          label: "تاریخ انقضا",
          sortable: true
        },
        {
          key: "operation",
          label: "عملیات",
        },

        // { key: 'parentId'},
      ],
      coupon: {
        code: "",
        condition: "",
        count: null,
        type: null,
        value: 0,
        expireDate: "",
        freeShipping: false,
        startDate: "",
      },
      currentPage: 1,
      perPage: 10,
      SelectedCoupon: null,
    };
  },
  async created() {
    await this.GetAllCoupon();
  },
  components: {
    ClearSortsBtn,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  watch: {
    currentPage: function () {
      this.GetAllCoupon();
    },
  },
  methods: {
    async UpdateCoupon() {
      let _this = this;
      let updateCoupon = new UpdateCoupon(_this);
      _this.loadingOverlay = true;
      if (_this.SelectedCoupon.type === 3) {
        _this.SelectedCoupon.freeShipping = true;
      } else {
        _this.SelectedCoupon.freeShipping = false;
      }
      updateCoupon.setData(_this.SelectedCoupon);
      await updateCoupon.fetch(
          function (content) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `کوپن بروزرسانی شد`,
              },
            });
            _this.SelectedCoupon = null;

            _this.GetAllCoupon();
          },
          function (error) {
            console.log(error);
          }
      );
      _this.loadingOverlay = false;
    },
    async CreateCoupon() {
      let _this = this;

      if (
          _this.coupon.code !== "" &&
          _this.coupon.count &&
          _this.coupon.type &&
          _this.coupon.expireDate !== ""
      ) {
        if (_this.coupon.type === 3) {
          _this.coupon.freeShipping = true;
        }
        _this.loadingOverlay = true;
        let createCoupon = new CreateCoupon(_this);

        createCoupon.setData(_this.coupon);
        await createCoupon.fetch(
            function (content) {
              _this.$toast({
                component: ToastificationContent,
                position: "bottom-center",
                props: {
                  title: `عملیات موفق`,
                  icon: "CheckIcon",
                  variant: "success",
                  text: `کوپن ساخته  شد`,
                },
              });
              _this.GetAllCoupon();
              _this.coupon = {
                code: "",
                condition: "",
                count: null,
                type: null,
                value: null,
                date: "",
                freeShipping: false,
              };
            },
            function (error) {
              console.log(error);
            }
        );
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: "bottom-center",
          props: {
            title: `عملیات ناموفق`,
            icon: "AlertCircleIcon",
            variant: "danger",
            text: `لطفا همه فیلد ها را پر کنید`,
          },
        });
      }
      _this.loadingOverlay = false;
    },
    async DeleteCoupon() {
      let _this = this;
      _this.loadingOverlay = true;

      let deleteCoupon = new DeleteCoupon(_this);
      deleteCoupon.setParams({id: this.SelectedCoupon.couponId});
      await deleteCoupon.fetch(
          function (content) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `کوپن  حذف شد.`,
              },
            });
            _this.GetAllCoupon();
          },
          function (error) {
            console.log(error);
          }
      );
      _this.loadingOverlay = false;
    },
    SetSelectedCoupon(item) {
      this.SelectedCoupon = JSON.parse(JSON.stringify(item));
    },
    async GetAllCoupon() {
      let _this = this;
      _this.loadingOverlay = true;
      let getAllCoupon = new GetAllCoupon(_this);
      let data = {
        pageNumber: _this.currentPage,
        count: 10,
      };
      getAllCoupon.setParams(data);
      await getAllCoupon.fetch(
          function (content) {
            _this.Coupons = content.coupons;
            _this.totalCount = content.couponsCount;
          },
          function (error) {
            console.log(error);
          }
      );
      _this.loadingOverlay = false;
    },
  },
};
</script>

<style scoped></style>
