import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllCoupon extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`Coupon/GetAll`);
    }

    setParams(data) {
    super.setRequestParam(data)
    }
}

class DeleteCoupon extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`Coupon/Delete`);
    }

    setParams(data) {
     super.setRequestParam(data)
    }
}
class GetCouponByCode extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`Coupon/GetByCode`);

    }

    setParams(data) {
        super.setRequestParam(data)
    }
}

class UpdateCoupon extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Coupon/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateCoupon extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Coupon/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

export {
    CreateCoupon,
    UpdateCoupon,
    GetCouponByCode,
    DeleteCoupon,
    GetAllCoupon
};
